angular.module('app.controllers').controller('FavsCtrl', ['$scope', '$rootScope', 'APIFavorites', 'Table', '$filter', 'APIReadTable', 'APIRead'].append(function(self, root, APIFavorites, Table, $filter, APIReadTable, APIRead) {
  var cookiesName, ref, ref1, tableName;
  tableName = 'c.fav.' + (((ref = root.user) != null ? ref.shortcode : void 0) || 'test');
  cookiesName = 't.' + tableName;
  self.listMode = false;
  self.toggleList = function() {
    return self.listMode = !self.listMode;
  };
  self.setType = function(type) {
    return self.favs.params.entry_type = type;
  };
  self.getType = function(type) {
    return self.favs.params.entry_type === type;
  };
  self.favs = Table({
    name: tableName,
    type: "partial",
    resource: APIReadTable,
    cookies: false,
    params: {
      limit: 12,
      orderby: "title",
      entry_type: "exhibitors",
      favs: true,
      shortcode: ((ref1 = root.user) != null ? ref1.shortcode : void 0) || root.tuser || 'test'
    }
  });
  self.page = 1;
  self.loadMore = function() {
    var params;
    if (self.page >= self.favs.data.pages) {
      return;
    }
    self.page++;
    params = angular.copy(self.favs.params);
    params.page = self.page;
    self.moreLoad = true;
    return APIRead.query(params, function(data) {
      self.more = data;
      self.favs.data.display = self.favs.data.display.concat(self.more);
      return self.moreLoad = false;
    });
  };
  return root.$on('forceFavs', function(event) {
    if (!self.at('favs')) {
      return;
    }
    return self.favs.reload();
  });
}));
