angular.module('app.controllers').controller 'FavsCtrl', [
  '$scope', '$rootScope', 'APIFavorites', 'Table', '$filter', 'APIReadTable', 'APIRead'
].append (self, root, APIFavorites, Table, $filter, APIReadTable, APIRead) ->
    

  tableName   = 'c.fav.' + (root.user?.shortcode || 'test')
  cookiesName = 't.' + tableName

  self.listMode = false
  self.toggleList = () ->
    self.listMode = !self.listMode

  self.setType = (type) ->
    self.favs.params.entry_type = type

  self.getType = (type) ->
    return self.favs.params.entry_type is type

  self.favs = Table
    name: tableName
    type: "partial"
    resource: APIReadTable
    cookies: false
    params: 
      limit: 12
      orderby: "title"
      entry_type: "exhibitors"
      favs: true
      shortcode: root.user?.shortcode || root.tuser || 'test'

  self.page = 1
  self.loadMore = () ->
    return if self.page >= self.favs.data.pages
    
    self.page++ 
    
    params = angular.copy(self.favs.params)
    params.page = self.page

    self.moreLoad = true
    APIRead.query params, (data) ->
      self.more = data
      self.favs.data.display = self.favs.data.display.concat(self.more)
      self.moreLoad = false


  root.$on 'forceFavs', (event) ->
    return unless self.at('favs')

    return self.favs.reload()
